<template>
  <v-autocomplete
    v-bind="attrs"
    v-on="listeners"
    :search-input.sync="search"
  />
</template>

<script>
import debounce from 'lodash/debounce'

export default {
  props: {
    value: {
      required: true,
      validate: (prop) => [null, undefined].includes(prop) || typeof prop === 'object'
    },
    load: {
      type: Function,
      required: true
    },
  },
  watch: {
    search (search) {
      this.fetch(search)
    }
  },
  mounted () {
    if (this.value) {
      const key = this.$attrs['item-text']
      this.$nextTick(() => {
        this.search = this.value[key]
      })
    }
  },
  data () {
    return {
      search: null,
      items: [],
      error: null,
      pending: false
    }
  },
  computed: {
    listeners () {
      return {
        ...this.$listeners,
        clear: () => {
          this.$emit('input', null)
        }
      }
    },
    attrs () {
      return {
        ...this.$attrs,
        value: this.value,
        items: this.items,
        returnObject: true,
        prependInnerIcon: "mdi-magnify",
        clearable: true,
        outlined: true,
        dense: true,
      }
    },
  },
  methods: {
    fetch: debounce(async function () {
      if (this.pending) return
      let data
      let error
      this.pending = true
      try {
        data = await this.load(this.search)
      } catch (e) {
        error = e
        console.error(e)
      }
      this.pending = false
      this.items = data
      this.loading = false
      this.error = error
      if (error) {
        this.$emit('error', error)
      }
    }, 500)
  }
}
</script>
